<template>
  <!-- eslint-disable -->
  <div id="app">
    <loading />
    <the-snackbar />
    <!-- The content of the page will be placed here -->
    <slot></slot>
  </div>
</template>

<script>
import TheSnackbar from '@/components/TheSnackbar.vue';
import Loading from '@/components/TheLoader.vue';

export default {

  name: 'minimal-layout',


  components: {
    TheSnackbar,
    Loading,
  },
};
</script>
