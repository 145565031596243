<template>
  <!-- black background -->
  <div class="ep-dialog">
    <!-- white container -->
    <div class="ep-dialog--container">
      <!-- *close button* -->
      <div class="close-icon" @click="$emit('close')">x</div>
      <div>
        <!-- *title and explaination*-->
        <h4 class="ep-subtitle ep-dialog--title">{{ $t('login.pawdLost.title') }}</h4>
        <p class="ep-font ep-font__no-side-margin">{{ stepContents[step] }}</p>
        <!-- *Step 0: recover the secret question* -->
        <form v-if="step === 0" @submit.prevent="getQuestion">
          <div class="ep-input-group">
            <!-- email in put field -->
            <label for="email" class="ep-input-group--label">
              {{ $t('login.pawdLost.email.label') }}
            :</label>
            <input
              name="email"
              class="ep-input-group--input"
              type="email"
              v-model="email"
              :placeholder="$t('login.pawdLost.email.placeholder')"
              required
            />
          </div>
          <button class="ep-submit">{{ $t('login.pawdLost.button.check') }}</button>
        </form>
        <form v-if="step === 1" @submit.prevent="getAnswer">
          <div class="ep-input-group">
            <label for="anwser" class="ep-input-group--label">{{ question }}</label>
            <input
              name="answer"
              class="ep-input-group--input"
              type="text"
              v-model="anwser"
              :placeholder="$t('login.pawdLost.answer.placeholder')"
              required
            />
          </div>
          <button class="ep-submit">{{ $t('login.pawdLost.button.submit') }}</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      step: 0,
      stepContents: [
        this.$i18n.t('login.pawdLost.text.one'),
        this.$i18n.t('login.pawdLost.text.two'),
        this.$i18n.t('login.pawdLost.text.three'),
      ],
      email: '',
      question: '',
      anwser: '',
    };
  },
  methods: {
    ...mapActions({
      getRecoveryQuestion: 'authentification/getRecoveryQuestion',
      sendReminder: 'authentification/sendReminder',
      loading: 'application/startLoading',
      displayError: 'application/openSnackbar',
    }),
    // prepare request to back-end and send it
    getQuestion() {
      this.loading(true);
      this.getRecoveryQuestion({ email: this.email }).then((response) => {
        if (response.code === 14) {
          this.displayError({
            text: this.$i18n.t('login.pawdLost.error.mail'),
            time: 10,
            centered: true,
            type: 'error',
          });
        } else if (response.code === 0) {
          this.question = response.content;
          this.step += 1;
        }
        this.loading(false);
      });
    },
    getAnswer() {
      this.loading(true);
      this.sendReminder({ email: this.email, answer: this.anwser }).then(
        (response) => {
          if (response.code === 15) {
            this.displayError({
              text: this.$i18n.t('login.pawdLost.error.answer'),
              time: 3,
              centered: true,
            });
          } else if (response.code === 0) {
            this.step += 1;
          }
          this.loading(false);
        },
      );
    },
  },
};
</script>
