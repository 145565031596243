<template>
  <ep-layout>
    <div class="ep-login--panel">
      <!-- recovery win for lost password -->
      <pwd-dialog v-if="pwdDialogIsVisible" @close="passwordRecovery"></pwd-dialog>
      <!-- <div class="close-icon" @click="$router.push({ name:'HomePage' })">x</div> -->
      <div :class="['ep-login--panel--part ep-login--panel--part__left', {'-MIPIH': isMIPIH}]">
        <div v-if="!isMIPIH" class="ep-login--panel--left-container">
        <h2 class="ep-login--panel--side-title">{{ $t('app.name') }}</h2>
        <h2 class="ep-login--panel--side-subtitle">
          {{ $t('activate.side-subtitle') }}
        </h2>
      </div>
      </div>
      <!-- login part -->
      <div class="ep-login--panel--part ep-login--panel--part__right">
        <h2 class="ep-login--panel--title">
          {{ isMIPIH ?
          $t('login.title.mipih') :
          $t('login.title') }}
        </h2>
        <form @submit.prevent="login">
          <div class="ep-login--panel-col-50">
            <!-- Login -->
            <div class="ep-input-group">
              <label class="ep-input-group--label" for="epLogin">
                {{ $t('login.label.login') }}
              </label>
              <input
                class="ep-input-group--input"
                id="epLogin"
                type="text"
                autocomplete="username"
                v-model="user.username"
                :placeholder="$t('login.label.login')"
                required
              />
            </div>
            <!-- Password -->
            <div class="ep-input-group">
              <label class="ep-input-group--label" for="epPwd">
                {{ $t('login.label.password') }}
              </label>
              <input
                class="ep-input-group--input"
                id="epPwd"
                type="password"
                autocomplete="current-password"
                v-model="user.password"
                :placeholder="$t('login.label.password')"
                required
              />
            </div>
            <div class="ep-link" @click="passwordRecovery">
              {{ $t('login.pawdLost.title') }}
            </div>
          </div>
          <br />
          <!-- submit button -->
          <input class="ep-submit" type="submit" :value="$t('login.submit.button')" />
        </form>
      </div>
    </div>
  </ep-layout>
</template>
<script>
import { mapState } from 'vuex';
import EpLayout from '@/layouts/Minimal.vue';
import PwdDialog from '@/components/dialog/LostPasswordDialog.vue';

export default {

  name: 'LoginIndex',


  data() {
    return {
      user: {
        username: null,
        password: null,
      },
      pwdDialogIsVisible: false,
    };
  },
  computed: {
    ...mapState({
      isMIPIH: state => state.auth.isMIPIH,
    }),
  },

  methods: {

    login() {
      this.$store.dispatch('auth/login', this.user);
    },
    passwordRecovery() {
      this.pwdDialogIsVisible = !this.pwdDialogIsVisible;
    },
  },

  components: {
    PwdDialog,
    EpLayout,
  },
};
</script>
